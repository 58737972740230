import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';


function Error({ }) {
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        console.log(window.userInfo.errors);
        setErrors(window.userInfo.errors);
    }, [window.userInfo?.errors]);
    return errors?.length > 0 ? <div className='error'>{errors[errors.length - 1]}</div> : null;
}

export default Error;
