import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const Navigation = () => {
    const [userInfo, setUserInfo] = useState(window.userInfo);
    return <nav>
        <div className='title'>
            <Link to={userInfo.name ? "/dashboard" : "/"}>
                <img src="/assets/images/logo.png" alt="" />
                <h1>One Dead Bird</h1>
                <p><strong>A new kind of game company</strong></p>
            </Link>
        </div>
        <ul>
            {userInfo?.name && <li><a href="/users/logout">Logout</a></li>}
            {!userInfo?.name && <li><Link to="/login">Login</Link></li>}
        </ul>
    </nav>
};

export default Navigation;