import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import Navigation from '../Navigation';
const heros = {
    dashboard: "assets/images/Hero-1.png",
    login: "assets/images/Hero-2.png",
    default: "assets/images/Hero-3.png",
}
const Heroes = () => {
    const location = useLocation();
    const page = location.pathname.replace('/','');
    console.log(heros[page]);
    return <div className='hero'>
        <div className='copy'>
            <Navigation />
        </div>
        <img src={heros[page] ? heros[page] : heros.default} alt="" />
    </div>
};

export default Heroes;