import React from 'react';
import { Link } from 'react-router-dom';
import Error from '../Shared/Error';

function Login() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    return (
        <div className='content'>
            <h1> Login </h1>
            <Error />
            <form action="/users/login" method="POST">
                <input name='ref' type='hidden' value={params.ref} />
                <div>
                    <label for="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                    />
                </div>
                <div>
                    <label for="password">Password</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Enter Password"
                    />

                </div>
                <button type="submit" class="btn btn-primary btn-block">Login</button>

            </form>
            <p>Don't Have An Account? <Link to={params.ref ? `/?ref=${params.ref}` : '/'}>Create One Now</Link></p>
        </div>
    );
}

export default Login;