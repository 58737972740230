import React from 'react';
import MisgnomerInventory from './MisgnomerInventory';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../CheckoutForm';
import Screens from './Screens';
function Dashboard() {
    console.log("stripe", window.userInfo.stripeId);
    const stripePromise = loadStripe(window.userInfo.stripeId);
    return (
        <div>
            <h1>Hi there </h1>
            <p>Welcome to one dead bird?</p>
            {/*<Screens />*/}
            <MisgnomerInventory />
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </div>
    );
}

export default Dashboard;