import React, { useState } from 'react';
import Heroes from '../Heros';
import { Link } from "react-router-dom";
import Error from '../Shared/Error';
function Register() {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [password2, setPassword2] = useState();
    const [subscribe, setSubscribe] = useState();
    return (
        <>
            <h1>Register</h1>
            <Error />
            <form action="/users/register" method="POST">
                <div>
                    <label for="name">Name</label>
                    <input
                        type="name"
                        name="name"
                        placeholder="Enter Name"
                        value={name}
                        onChange={({ value }) => {
                            setName(value);
                        }}
                    />

                </div>
                <div>
                    <label for="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={({ value }) => {
                            setEmail(value);
                        }}
                    />
                </div>
                <div >
                    <label for="password">Password</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Create Password"
                        value={password}
                        onChange={({ value }) => {
                            setPassword(value);
                        }}
                    />
                </div>
                <div >
                    <label for="password2">Confirm Password</label>
                    <input
                        type="password"
                        name="password2"
                        placeholder="Confirm Password"
                        value={password2}
                        onChange={({ value }) => {
                            setPassword2(value);
                        }}
                    />
                </div>
                <div>
                    <label for="subscribe">Subscribe for more information</label>
                    <input
                        type="checkbox"
                        name="subscribe"
                        placeholder="Enter subscribe"
                        value={subscribe}
                        onChange={({ value }) => {
                            setSubscribe(value);
                        }}
                    />
                </div>
                <button type="submit" class="btn btn-primary btn-block">
                    Register
                </button>
            </form>
            <p >Have An Account? <Link to="/login">Login</Link></p>

        </>
    );
}

export default Register;