import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

function MisgnomerInventory() {
    const [inventory, setInventory] = useState(null);
    useEffect(() => {
        fetch("/api/misgnomer", {})
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                setInventory(data);
            });
    }, [])
    if (inventory) {
        console.log(Object.entries(inventory).length);
    }
    return (inventory ?
        <div>
            <strong>Character</strong>{' '}
            <span>{inventory.character}</span>
            <h3>Inventory:</h3>
            <ul>
                {inventory.items &&
                    Object.entries(inventory.items).filter(([key]) => key !== 'any').map(([key]) => {
                        return <li>
                            <strong>{key}</strong>{' '}
                            <span>{inventory.items[key]}</span>
                        </li>
                    })
                }
            </ul>
            <h3>Premium Items:</h3>
            <ul>
                {inventory.premiumItems &&
                    inventory.premiumItems.map((key) => {
                        return <li>
                            <strong>{key}</strong>
                        </li>
                    })
                }
            </ul>
            <h3>Equipped Items:</h3>
            <ul>
                {inventory.equippedItems &&
                    inventory.equippedItems.map((key) => {
                        return <li>
                            <strong>{key}</strong>
                        </li>
                    })
                }
            </ul>
        </div>
        :
        <div>Loading</div>
    );
}

export default MisgnomerInventory;