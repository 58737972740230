import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    setLoading(false);

    if (error) {
      console.error(error);
    } else {
      console.log(paymentMethod);
      // Make API call to your server to process the payment
      fetch("/pay", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify({ token: paymentMethod, items: ["birthdayHat"] }), // body data type must match 
      })
        .then(response => response.json())
        .then((data) => {
          console.log(data);
          setInventory(data);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        {loading ? 'Loading...' : 'Pay $1'}
      </button>
    </form>
  );
};

export default CheckoutForm;
