import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

import App from './app.jsx';
import Register from './Register/';
import Login from './Login/';
import Dashboard from './Dashboard/';
const appRouting = (
  <ThemeProvider
    breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
    minBreakpoint="xxs"
  >
    <Router>
      <Routes>
        <Route path="/" element={<App><Register /></App>}>
          <Route index element={<App><Register /></App>} />
        </Route>
        <Route path="/login" element={<App><Login /></App>}>
        </Route>
        <Route path="/dashboard" element={<App><Dashboard /></App>}>
        </Route>
      </Routes>
    </Router >
  </ThemeProvider >
);
ReactDOM.render(appRouting, document.getElementById('root'));
