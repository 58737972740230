import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import Heroes from './Heros';

function App({ children }) {
  return (
    <>
      <Heroes />
      <div className='content'>
        {children}
      </div>
    </>
  );
}

export default App;
