import React, { useState } from 'react';
import { useEffect } from 'react';
function Screens() {
    const [screens, setScreens] = useState([]);
    useEffect(() => {
        fetch("/api/screens", {})
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                setScreens(data)
            });
    }, []);

    return (
        <div>
            Screens
            <ul>
                {
                    screens.map(({ screenId, roomId }) => {
                        if (!screenId) {
                            return null;
                        }
                        return <li><strong>{screenId}</strong>: <a href={`https://misgnomer.onedeadbird.com/controller?roomId=${roomId}`} target='_blank'>{roomId}</a></li>
                    })
                }
            </ul>
        </div>
    );
}

export default Screens;